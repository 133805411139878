////////////////////////////////////////////////////////////////////////
//////// Photo upload
////////////////////////////////////////////////////////////////////////

.m13 {
  //display: flex;
  margin-top: 20px;
  //flex-direction: row;

  @include media-breakpoint-up(sm) {
    //flex-direction: row;
  }

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: $spacer / 2;

    h5 {
      color: $dark;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
      //margin-left: 5px;
    }
  }

  .subtitle {
    color: $dark;
    opacity: 0.6;
    font-size: 13px;
    font-weight: normal;
  }

  .main-upload {
    width: 100%;
    overflow: hidden;

    //@include media-breakpoint-up(sm) {
    //  width: 50%;
    //  margin-right: 5px;
    //}

    &.main-upload--primary {
      .photo-upload-text {
        margin-bottom: $spacer * 2;
        //flex-basis: 50%;
      }
      .photo-upload {
        display: flex;
        .main-uploader {
          flex-basis: 50%;
          position: relative;
          &--right {
            margin-left: $spacer * 2;

          }
        }
      }
    }
    &.main-upload--emblem {
      margin-top: 50px;
      .emblem-check {
        display: flex;
        .main-uploader {
          flex-basis: 50%;
          position: relative;
        }
        .emblem-check-text {
          margin-left: $spacer * 2;
          flex-basis: 50%;
        }
      }
    }

    &.main-upload--primary {
      .main-upload-bd {
        .uploader__dropzone {
          //background: url('/assets/images/example-main-upload.png');
        }
      }
    }

    .main-uploader {
      position: relative;

      .main-uploader-upload-container {
        position: relative;
        .main-uploader-upload-container-click-area {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      .icon-button {
        position: absolute;
        z-index: 11;
        right: 10px;
        top: 10px;
        background: transparent;
        outline: none;
        border: 0;
        color: white;
        font-size: 20px;

        &:hover {
          color: $primary;
        }
      }

      .main-upload-bd {
        position: relative;

        &.disabled {
          pointer-events: none;
        }

        .uploader__dropzone {
          position: relative;
          padding: 5.5rem;
          min-height: 218px;
          overflow: hidden;
        }
      }

      .uploader__dropzone__legend {
        padding: 6px 10px;

        .no-upload {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          background: transparent;
          cursor: pointer;
          left: 0;
          z-index: 9;
          background: $white;

          span {
            padding: 10px;
          }
        }

        .preview {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
          left: 0;
          z-index: 9;
          border-radius: $border-radius;
          background-color: #F0F0EA;
          overflow: hidden;

          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 90%;

          }


          &.preview--cover {
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              cursor: pointer;
            }

          }
        }
      }
      .check-btns {
        position: absolute;
        right: 7px;
        top: 7px;
        z-index: 10;
        .btn {
          text-transform: uppercase;
        }
        .btn-secondary {
          margin-left: 5px;
        }
      }
      &.main-uploader--image {
        .uploader__dropzone {
          border-color: #F0F0EA;
        }
      }
    }
  }
}
