////////////////////////////////////////////////////////////////////////
//////// Override Bootstrap
////////////////////////////////////////////////////////////////////////

$white: #FFFFFF;
$black: #000000;
$dark: #161616;
$primary: #07F468;
$secondary: #444545;
$info: #C8CFFFFF;
$body-bg: $dark;
$navbar-dark-color: $white;
$navbar-dark-active-color: $primary;
$navbar-brand-height: 2rem;
$navbar-height: 68px;
$navbar-nav-link-padding-x: 20px;
$navbar-padding-x: 50px;
$font-family-sans-serif: 'Cruyff Sans', open-sans, sans-serif;
$font-family-base: 'Cruyff Sans', open-sans, sans-serif;
$headings-font-family: 'Cruyff Sans', din-condensed, sans-serif;
$font-weight-normal: normal;
$font-weight-base: normal;
$font-weight-bold: bold;
$headings-font-weight: normal;
$link-color: $primary;
$link-hover-color: $secondary;
$link-hover-decoration: none;
$font-size-base: 0.9rem;
$border-radius: 12px;

$btn-border-radius: 20px;
$btn-border-radius-lg: 20px;
$btn-border-radius-sm: 20px;
$btn-padding-y: 8px;
$btn-padding-x: 20px;
$btn-font-size: 16px;
$btn-disabled-opacity: 0.3;
$btn-padding-x-sm: 10px;
$btn-line-height-sm: 1.1;

$input-height: 66px;
$input-border-width: 1px;
$input-font-size: 15px;
$input-btn-padding-x: 1rem;
$input-border-color: #787873;
$input-bg: $white;
$input-placeholder-color: #999;

$badge-font-weight: normal;

$modal-content-border-radius: $border-radius;
$modal-header-border-width: 0;
$modal-inner-padding: 2rem;
$modal-header-padding: 2rem 2rem 0 2rem;

$badge-font-size: 50%;
$badge-padding-x: 6px;
$badge-padding-y: 4px;
$badge-border-radius: 4px;

$h2-font-size: 32px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 960px,
);

//$theme-colors: (
//  'primary': $primary,
//  'secondary': $secondary,
//  'success': $success,
//  'info': $info,
//  'warning': $warning,
//  'danger': $danger,
//  'light': $light,
//  'dark': $dark,
//);

// Cards
$card-spacer-y: 0.75rem !default;
$card-spacer-x: 50px !default;
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: transparent;
$card-inner-border-radius: 0;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$grid-gutter-width: 30px;

$card-group-margin: $grid-gutter-width / 2 !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
//$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: 0;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

$pagination-padding-y: 23px;
$pagination-bg: $input-bg;
$pagination-border-width: 2px;
$pagination-border-color: $input-border-color;
$pagination-disabled-bg: #e9ecef;
$pagination-disabled-border-color: darken(#e9ecef, 10%);
