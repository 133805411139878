@font-face {
  font-family: 'Cruyff Sans Mono';
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Medium.eot');
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Medium.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Medium.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans Mono';
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Light.eot');
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Light.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Light.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans Extended';
  src: url('../../assets/fonts/cruyff/CruyffSansExtended-Medium.eot');
  src: url('../../assets/fonts/cruyff/CruyffSansExtended-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSansExtended-Medium.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSansExtended-Medium.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSansExtended-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans Mono';
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Regular.eot');
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Regular.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Regular.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans Mono';
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Bold.eot');
  src: url('../../assets/fonts/cruyff/CruyffSansMono-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Bold.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Bold.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSansMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans';
  src: url('../../assets/fonts/cruyff/CruyffSans-Bold.eot');
  src: url('../../assets/fonts/cruyff/CruyffSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSans-Bold.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSans-Bold.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans';
  src: url('../../assets/fonts/cruyff/CruyffSans-Light.eot');
  src: url('../../assets/fonts/cruyff/CruyffSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSans-Light.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSans-Light.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans';
  src: url('../../assets/fonts/cruyff/CruyffSans-Regular.eot');
  src: url('../../assets/fonts/cruyff/CruyffSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSans-Regular.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSans-Regular.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans';
  src: url('../../assets/fonts/cruyff/CruyffSans-Medium.eot');
  src: url('../../assets/fonts/cruyff/CruyffSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSans-Medium.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSans-Medium.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cruyff Sans Expanded';
  src: url('../../assets/fonts/cruyff/CruyffSansExpanded-Heavy.eot');
  src: url('../../assets/fonts/cruyff/CruyffSansExpanded-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/cruyff/CruyffSansExpanded-Heavy.woff2') format('woff2'),
  url('../../assets/fonts/cruyff/CruyffSansExpanded-Heavy.woff') format('woff'),
  url('../../assets/fonts/cruyff/CruyffSansExpanded-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

