////////////////////////////////////////////////////////////////////////
//////// Chip
////////////////////////////////////////////////////////////////////////

.chip {
  display: inline-block;
  padding: 0 15px 0 15px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: $gray-200;
  font-size: $small-font-size;

  &.chip--action {
    padding-right: 0;
  }

  img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .close-btn {
    margin-left: 10px;
    background-color: $gray-300;
    color: $gray-500;
    font-weight: bold;
    float: right;
    font-size: 16px;
    border-radius: 15px;
    width: 30px;
    line-height: 28px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: $light;
      background-color: $dark;
    }
  }
}
