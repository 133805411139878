.m16 {
  h3 {
    text-transform: none;
  }
  h3,
  p {
    text-align: center;
  }
  @include media-breakpoint-up(lg) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    .buttons {
      display: flex;
      .btn {
        display: block;
        flex: 1;
      }
    }
  }
}
