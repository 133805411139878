////////////////////////////////////////////////////////////////////////
//////// Delivery Feed
////////////////////////////////////////////////////////////////////////

.m03 {
  &.m03--open {
    padding: 0;
    max-width: none;
    display: flex;
    height: calc(100vh - #{$navbar-height});
    overflow: hidden;
    .m01 {
      width: map-get($container-max-widths, 'md');
      overflow-y: scroll;
    }
    .m03__gallery {
      flex: 2;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      overflow: scroll;
      height: auto;
      .m01 {
        width: 100%;
        order: 2;
      }
      .m03__gallery {
        order: 1;
        height: calc(100vh - #{$navbar-height});
      }
    }
  }

  .m03__wrapper {
    padding: ($spacer * 5) $spacer $spacer $spacer;
    height: 100%;
    position: relative;
    background-color: $black;

    .m03__wrapper__header {
      position: absolute;
      padding-top: $spacer * 1.4;
      padding-left: $spacer * 1.5;
      padding-right: $spacer * 1.5;
      left: 0;
      top: 0;
      z-index: 99;
      width: 100%;
      display: flex;
      align-items: center;
      h2 {
        font-size: $font-size-lg;
        color: $white;
        flex: 2;
      }
      .m03__wrapper__header__actions {
        display: flex;
        align-self: flex-end;

        .m03__wrapper__header__actions__download {
          a {
            margin-right: $spacer;
            color: $white;
            &:hover {
              color: $primary;
            }
          }
        }

        .m03__wrapper__header__actions__close {
          font-size: $font-size-lg * 1.3;
          color: $white;
          z-index: 99;
          cursor: pointer;
          &:hover {
            color: $primary;
          }
        }
      }
    }

    .ngx-gallery-image-wrapper {
      background-color: $dark;
    }

    ngx-gallery-thumbnails {
      background-color: $black;
      .ngx-gallery-thumbnail-size-cover {
        .ngx-gallery-thumbnails {
          .ngx-gallery-thumbnail {
            background-size: contain !important;
            background-color: $dark;
          }
        }
      }
    }

    .ngx-gallery-image {
      background-size: contain !important;
    }
  }
  .ngx-gallery-thumbnails .ngx-gallery-thumbnail .ngx-gallery-thumbnail-video:after {
    text-align: center;
  }
}
