.m21 {
  display: flex;
  .m21__text {
    flex-grow: 1;
  }
  .m21__example {
    margin-left: $spacer * 3;
    margin-right: $spacer * 2;
    position: relative;
    .badge {
      display: inline-block;
      transform: translateX(-50%);
      position: absolute;
      bottom: -7px;
      left: 50%;
      padding: 8px 10px;
      text-transform: uppercase;
    }
  }
}
