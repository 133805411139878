////////////////////////////////////////////////////////////////////////
//////// M10 Form
////////////////////////////////////////////////////////////////////////

.m10 {
  .m10__actions {
    .btn {
      display: block;
      width: 100%;
    }
    .m10__actions__link {
      margin-top: $spacer;
      text-align: center;
      a {
        color: $dark;
        margin-top: $spacer;
        font-size: $small-font-size;
        transition: all 0.5s;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
