.m14 {

  background: #F1F0EF;
  padding: 0;

  .m14__title {
    background-color: #23A566;
    text-align: center;
    color: $white;
    font-size: $font-size-sm;
    padding: $spacer 0;
  }

  .m14__table {
    padding-top: $spacer;
    padding-bottom: $spacer;

  }

  .row {
    margin-right: -4px;
    margin-left: -4px;

    > div {
      padding-right: 4px;
      padding-left: 4px;
    }
  }

  .col-xs-number {
    width: 30px;
    text-align: right;
  }

  .table-header {
    font-size: 10px;
    color: #161616;
    font-weight: 600;
  }

  .table-data-container {
    padding-left: $spacer;
    padding-right: $spacer;

    .table-data {
      margin-top: 5px;
      width: 100%;
      padding: 0;
      outline: none;
      border: none;
      background: transparent;
      text-align: left;

      &.isChecked {
        .table-item {
          border-color: #335ab2;
        }

        .ready {
          border-color: #335ab2;
        }

        .number {
          color: #335ab2;
        }
      }

      .number {
        color: #161616;
        font-size: 12px;
        font-weight: 600;
        min-width: 30px;
      }

      .ready {
        font-size: 10px;
        background: white;
        padding: 6px 8px;
        border-radius: 2px;
        width: 31px;
        padding-top: 2px;
        height: 27px;
        border: 1px solid transparent;

        .custom-control {
          padding-left: 22px;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
          color: #fff;
          border-color: black !important;
          background-color: black !important;
        }

        .custom-control-input:focus ~ .custom-control-label::before {
          border-color: black !important;
          box-shadow: none !important;
        }
      }

      .table-item {
        font-size: 10px;
        background: white;
        padding: 6px 10px;
        border-radius: 2px;
        border: 1px solid transparent;
      }
    }
  }
}
