////////////////////////////////////////////////////////////////////////
//////// Upload
////////////////////////////////////////////////////////////////////////

.m11 {
  .m11__gallery {
    margin-bottom: $spacer;
    @include make-row(2px);
    position: relative;
    .m11__gallery__image {
      @include make-col-ready(2px);
      @include make-col(6, $grid-columns);
      margin-bottom: 2px;
    }
  }
}
