.m06 {
  display: block;
  margin-bottom: $spacer / 3;
  > ul {
    padding: 0;
    margin: 0;
    > li {
      list-style-type: none;
      padding: 0;
      margin: 0 ($spacer / 1.5) 0 0;
      display: inline-block;
      a,
      span,
      .fas,
      .dropdown-toggle {
        color: $gray-500;
        font-size: $font-size-base;
      }
      .fas {
        margin-left: $spacer / 1.5;
        font-size: $font-size-base * 0.7;
      }
      .dropdown-toggle {
        margin-top: -1px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .dropdown-toggle-split {
        padding-left: 0;
        padding-right: 0;
        .caret {
          margin-left: $spacer / 2;
        }
      }
    }
  }
}
