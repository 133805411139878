.m22 {
  display: flex;
  margin: -12px 0 0 -12px;
  width: calc(100% + 12px);
  margin-bottom: 20px;
  .m22__crest,
  .m22__main {
    flex-basis: 50%;
    height: 180px;
    background-color: #F0F0EA;
    border-radius: $border-radius;
    margin: 12px 0 0 12px;
    overflow: hidden;
  }
  .m22__crest {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 90%;

    }
  }
  .m22__main {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
