////////////////////////////////////////////////////////////////////////
//////// Navbar
////////////////////////////////////////////////////////////////////////

.navbar {
  background-color: $black;
  height: 66px;
  &:after {
    height: 2px;
    content: '';
    width: 100%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$info+0,de0cec+100 */
    background: $primary;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
  .navbar-nav {
    .nav-link {
      font-family: $headings-font-family;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      display: block;
      padding-top: 23px;
      padding-bottom: 20px;
    }
  }
}
