.m17 {
  display: flex;
  .m17__upload,
  .m17__crest {
    height: 120px;
  }
  .m17__crest {
    width: 120px;
    height: 120px;
    margin-right: $spacer;
    padding: $spacer;
    background-color: #eee;
    border-radius: $border-radius;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .m17__upload {
    flex-grow: 1;
    .form-group {
      margin-bottom: 0;
    }
    .uploader .uploader__dropzone {
      height: 120px;
      .uploader__dropzone__legend {
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
