////////////////////////////////////////////////////////////////////////
//////// M08 Delivery Header
////////////////////////////////////////////////////////////////////////

.m08 {
  display: flex;
  align-items: flex-start;
  .m08__image {
    margin-right: $spacer;
    position: relative;
    img {
      width: 120px;
      @include media-breakpoint-down(md) {
        width: 90px;
      }
    }
  }

  .edit-emblem {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e41af;
    color: white;
    border-radius: 100vw;
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;

    i {
      font-size: 0.85rem;
    }
  }

  .m08__text {
    flex: 2;
    position: relative;
    h2,
    h3 {
      margin: 0;
      text-transform: uppercase;
      .badge {
        margin-left: $spacer / 3;
        font-size: 0.5em;
        padding: 7px 7px 4px;
        line-height: 0.6em;
        position: relative;
        text-transform: uppercase;
      }
    }
    .m08__text__detail-cta {
      position: absolute;
      right: 0;
      top: -16px;
      cursor: pointer;
      color: $gray-300;
      font-size: 40px;
      transition: all 0.3s;
      &.m08__text__detail-cta--open {
        color: $dark;
      }
    }
    .chip {
      margin-right: $spacer / 2;
      margin-bottom: $spacer / 2;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
