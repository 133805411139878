.m18 {
  display: flex;
  margin-bottom: $spacer;
  .m18__index {
    width: 30px;
    text-align: left;
    font-size: $h3-font-size;
    padding-top: 15px;

  }
  .m18__name {
    margin-right: $spacer;
  }
  .m18__name,
  .m18__email {
    flex-grow: 1;
    .form-group {
      margin-bottom: 0;
    }
  }
  .m18__actions {
    width: 50px;
    text-align: right;

    padding-top: 15px;
    i {

      font-size: $h3-font-size;
    }
    &.m18__actions--disabled {
      visibility: hidden;
    }
  }
}
