////////////////////////////////////////////////////////////////////////
//////// M09 Users
////////////////////////////////////////////////////////////////////////

.m09 {
  margin-top: $spacer * 2;
  .m09__users {
    margin-bottom: $spacer;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .m09__users,
  .m09__settings {
    p {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      color: $dark;
      margin-bottom: $spacer * 0.5;
    }
  }
}
