.m15 {
  background: rgba(255, 255, 255, 0.1);
  border: 2px dashed #D8D8D8;
  text-align: center;
  margin: $spacer 0;
  transition: all 0.5s;
  a {
    transition: all 0.5s;
    color: $white;
    padding: 22px;
    display: block;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-size: 18px;
    .fa {
      margin-right: $spacer / 2;
    }
  }
  &:hover {
    border-color: $primary;
    a {
      color: $primary;
    }
  }
  &--light {
    a {
      color: $body-color;

    }
  }
}
