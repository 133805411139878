.m19 {
  margin-bottom: $spacer * 2;
  background: rgba(159, 155, 150, 0.15);
  padding: $spacer * 2;
  border-radius: $border-radius;
  position: relative;
  .m19__topline {
    font-weight: $font-weight-bold;
    opacity: 0.4;
  }
  .m19__topline {
    font-size: $font-size-sm;
  }
  .m19__edit {
    position: absolute;
    right: $spacer;
    top: $spacer;
    color: $body-color;
    opacity: 0.4;
    transition: all 0.5s;
    i {
      font-size: $h3-font-size;
    }
    &:hover {
      color: $primary;
      opacity: 1;
    }
  }
}
