body {
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

blockquote {
  border-left: 4px solid $gray-200;
  padding-left: $spacer;
  font-style: italic;
  padding-top: $spacer;
  padding-bottom: $spacer;
}

strong {
  font-weight: $font-weight-bold;
}
