////////////////////////////////////////////////////////////////////////
//////// Delivery Feed
////////////////////////////////////////////////////////////////////////

.m01 {
  background-color: $white;
  display: block;
  .m08 {
    padding: $spacer * 1.5;
    border-bottom: 1px solid $gray-300;
    @include media-breakpoint-down(md) {
      padding: $spacer;
    }
  }

  .m01__actions {
    margin-top: $spacer;
    padding: $spacer * 1.5;
    @include media-breakpoint-down(md) {
      padding: $spacer;
    }
    a {
      color: $dark;
      transition: all 0.5s;
      &:hover {
        color: $primary;
      }
    }
  }

  .m01__public_links {
    margin-top: $spacer;
    p {
      font-size: $font-size-sm;
    }
  }

  // Comment form
  .m07 {
    padding: $spacer * 1.5;
    border-bottom: 1px solid $gray-300;
    @include media-breakpoint-down(md) {
      padding: $spacer;
    }
    .description-text {
      margin-bottom: $spacer * 1.5;
    }
  }

  .circle {
    border-radius: 50%;
    font-size: 16px;
    height: 15px;
    padding: 10px;
    display: inline-flex;
    width: 15px;
    background: #1e41af;
    color: white;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
}
