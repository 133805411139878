////////////////////////////////////////////////////////////////////////
//////// M05 File
////////////////////////////////////////////////////////////////////////

.m05 {
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
  background-color: $gray-200;

  &.m05--clickable {
    cursor: pointer;
  }

  .m05__dummy,
  .m05__image {
    width: 100%;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
  .m05__dummy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
  }

  &:hover {
    .m05__dummy,
    .m05__image {
      background-color: $gray-300;
    }
  }

  .m05__image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .m05__file {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $spacer;
    span {
      display: block;
      font-size: $small-font-size;
      text-align: center;
    }
  }

  .m05__play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .m05__caption {
    position: absolute;
    background-color: $dark;
    width: 100%;
    padding: $spacer / 2;
    font-size: $font-size-sm;
    color: $white;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: all 0.2s;
  }

  &:hover {
    .m05__caption {
      transform: translateY(0%);
    }
  }
}
