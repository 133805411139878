//
// CSS
// ---------------------------------------------------------

//
// Imports
// ---------------------------------------------------------

@import '~bootstrap/scss/functions';

@import 'variables';
@import '~bootstrap/scss/variables';


@import '~@ng-select/ng-select/scss/default.theme.scss';

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@import 'layout/layout';
@import 'layout/typo';
@import 'layout/navbar';
@import 'layout/form';
@import 'layout/helper';
@import 'layout/buttons';
@import 'layout/modal';
@import 'layout/badge';

@import 'fonts/cruyff';

@import 'elements/chip';

@import 'modules/m01_delivery_feed';
@import 'modules/m02_delivery_feed_activity';
@import 'modules/m03_gallery_view';
@import 'modules/m04_delivery_list';
@import 'modules/m05_file';
@import 'modules/m06_rootline';
@import 'modules/m07_comment';
@import 'modules/m08_delivery_header';
@import 'modules/m09_delivery_users';
@import 'modules/m10_form';
@import 'modules/m11_upload';
@import 'modules/m12_public_links';
@import 'modules/m13_photo_upload';
@import 'modules/m14_import_list';
@import 'modules/m15_add_item';
@import 'modules/m16_wizard_step';
@import 'modules/m17_crest_upload';
@import 'modules/m18_approver_list';
@import 'modules/m19_legal_line_list_item';
@import 'modules/m20_no_results';
@import 'modules/m21_league_approval';
@import 'modules/m22_image_delivery_preview';

// Font Awesome
// --------------------------------------------------

@import 'fontawesome-all.css';
