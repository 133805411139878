.m07 {
  display: block;

  .description-text {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    font-size: 12px;
    color: #161616;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .text-block {
      font-size: 14px;

      @include media-breakpoint-up(sm) {
        margin-right: 60px;
        font-size: 16px;
      }

      .circle {
        margin-bottom: 2px;
      }
    }

    .footnote {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 600;
      opacity: 0.6;

      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }
    }

    .example-image {
      height: 370px;
      width: 350px;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;

      .example-image-caption {
        text-align: center;
        color: #787873;
        margin-top: 5px;
        font-size: 11px;
        line-height: 13px;
      }

      img {
        width: 310px;
      }

      .splide__slide {
        padding: 20px 20px 30px 20px;
      }

      .splide__arrow {
        height: 15px;
        width: 15px;
        background-color: transparent;
        border: 1px solid #000;
        &--prev {
          left: 0px;
        }
        &--next {
          right: 0px;
        }
        svg {
          height: 0.8em;
          width: 0.8em;
        }
      }
      .splide__pagination__page {
        background: $primary;
        &.is-active {
          background: $primary;
          transform: scale(1.4);
          z-index: 1;
        }
      }

      .first-circle {
        position: absolute;
        left: 20px;
        top: 50px;
      }

      .second-circle {
        position: absolute;
        top: 50px;
        left: -4px;
      }

      .example-badge {
        position: absolute;
        bottom: -10px;
        color: $dark;
        background: $primary;
        padding: 5px 10px;
        border-radius: 3px;
        text-transform: uppercase;
        width: 82px;
        height: 30px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .m07__attachments {
    display: none;
    margin-top: $spacer;

    &.m07__attachments--open {
      display: block;
    }
  }

  .m07__textarea {
    position: relative;
    .m07__textarea__attachments {
      position: absolute;
      right: 20px;
      top: 50%;
      font-size: $font-size-base * 2;
      transform: translateY(-50%);
      color: $gray-700;
      cursor: pointer;
      &:hover {
        color: $dark;
      }
    }
  }

  .m07__submit {
    margin-top: $spacer;
  }

  .m07__thumbs {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    .m05 {
      display: flex;
      width: 120px;
    }
  }
}
