////////////////////////////////////////////////////////////////////////
//////// Form
////////////////////////////////////////////////////////////////////////

.uploader {
  .uploader__dropzone {
    border: 1px solid $input-border-color;
    padding: $spacer * 2.5;
    vertical-align: center;
    display: flex;
    justify-content: center;
    transition: all 0.5s;
    border-radius: $border-radius;
    .uploader__dropzone__legend {
      color: #999;
      cursor: pointer;
      span strong {
        display: none;
      }
    }
    &:hover {
      background-color: $gray-200;
    }
  }

  .uploader__queue__item {
    background-color: $gray-100;
    display: flex;
    margin-top: $spacer;
    .uploader__queue {
      &.uploader__queue--content {
        .uploader__queue--text {
          display: none;
        }
        img {
          max-width: 150px;
        }
      }
      &.uploader__actions {
        flex: 2;
        display: flex;
        align-items: center;
        .far {
          cursor: pointer;
        }
      }
    }
  }
}

.form-group {
  &.has-errors {
    textarea,
    input[type='password'],
    input[type='text'] {
      border-color: $danger;
    }
  }
}

.form-errors {
  margin: ($spacer * 0.5) 0 0 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: $danger;
    font-size: $small-font-size;
  }
}

.ng-select {
  margin-bottom: 0;
  .ng-select-container {
    min-height: 66px;
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    .ng-value-container {
      .ng-input {
        &>input {
          height: 50px;
        }
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      height: 66px;
    }
  }
  .ng-arrow-wrapper {
    padding-right: $spacer * 2;
    .ng-arrow {
    }
  }
  .ng-clear-wrapper {
    width: 25px;
  }
  .ng-select-container {
    .ng-value-container {
      padding-left: $spacer;
      padding-right: $spacer / 2;
      .ng-value {
        color: #495057;
      }
    }
  }
  &.ng-select-opened > .ng-select-container {
    background-color: $input-bg;
    border: 1px solid $input-border-color;

  }
  &.ng-select.ng-select-disabled > .ng-select-container {
    border-color: darken($input-disabled-bg, 10%);
  }
  &.ng-select-multiple .ng-select-container .ng-value-container {
    padding-left: 15px;
    .ng-value {
      background-color: $primary;
      color: $dark;
      border-radius: 20px;
      padding: 5px 10px;
      .ng-value-icon {
        &:hover {
          background-color: transparent;
          color: $white;
        }
        &.left {
          border: 0;
        }
      }
    }
  }
}

bd-radio {
  .form-input {
    div {


      @include media-breakpoint-up(sm) {
        display: flex;
      }
      .checkbox {
        margin-right: $spacer;
        label {
          input {
            margin-right: $spacer / 2;
          }
          span {
            font-size: $font-size-lg;
            font-weight: $font-weight-normal;
          }
        }
      }
    }
  }
}

bd-upload {
  .form-legend {
    display: none;
  }
}

bd-checkbox {
  position: relative;
  input {
    position: absolute;
    left: 0px;
    top: 7px;
  }
  label {
    font-size: $font-size-sm;
  }
  .checkbox {
    span {
      display: block;
      margin-left: $spacer * 1.5;
      font-size: $font-size-lg;
    }
  }

  .form-group,
  .form-label {
    margin-bottom: 0;
  }
}

.form-readonly {
  display: none;
}

.form-control:disabled,
.form-control[readonly] {
  border-color: darken($input-disabled-bg, 10%);
}
