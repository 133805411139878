app-approval-delivery-package-status,
app-approval-delivery-status {
  display: inline-block;
  top: -4px;
  position: relative;
}

.badge {
  font-size: 1rem;
}
