////////////////////////////////////////////////////////////////////////
//////// M08 Delivery Header
////////////////////////////////////////////////////////////////////////

.m04 {
  display: block;

  .m04__progress {
    margin-bottom: $spacer * 1.5;
    background: $white url('/assets/images/headergrafik-lg.jpg') no-repeat right 50%;
    background-size: contain;
    @include media-breakpoint-down(md) {
      background-position: 120% 50%;
    }
    @include media-breakpoint-down(sm) {
      background-image: none;
    }

    .m04__progress__bar {
      margin-top: $spacer * 2;

      .m04__progress__bar__legend {
        margin: $spacer 0 0 0;
        dt,
        dd {
          display: inline-block;
          font-size: $font-size-sm;
        }
        dt {
          text-transform: uppercase;
          margin-right: $spacer * 0.5;
        }
        dd {
          margin-right: $spacer * 2;
        }
      }
    }

    .m04__progress__help {
      font-size: $font-size-sm;
    }
  }

  .m04__filter {
    margin: ($spacer * 2) 0 $spacer 0;
    .form-group {
      margin-bottom: 0;
    }
    filter-component {
      input {
        background-color: $dark;
        color: $white;
        border: 1px solid $gray-700;
        padding: $spacer * 2;
        font-size: 15px;
      }
      input::placeholder {;
        color: #999;
      }
    }
    .ng-select {
      margin-bottom: 0;
      .ng-select-container {
        min-height: 66px;
        background-color: $dark;
        border: 1px solid $gray-700;
      }
      &.ng-select-single {
        .ng-select-container {
          height: 66px;
        }
      }
      .ng-arrow-wrapper {
        padding-right: $spacer * 2;
        .ng-arrow {
          border-color: $white transparent transparent;
        }
      }
      .ng-clear-wrapper {
        width: 25px;
      }
      .ng-value-container {
        padding-left: $spacer * 2;
        .ng-value {
          color: $white;
        }
      }
    }

    .badges {
      margin-top: 1.5rem;
      margin-bottom: -0.25rem;
      button {
        all: unset;
        cursor: pointer;

        &:not(.active) {
          opacity: 0.4;
        }

        //app-approval-delivery-status {
        //  span {
        //    font-size: 1rem;
        //    text-transform: uppercase;
        //  }
        //}
      }
    }
  }

  .m08 {
    padding: $spacer * 1.5;
    background-color: $white;
    margin-bottom: $spacer;
    cursor: pointer;
    transition: all 0.5s;


    .m08__action__delete {
      display: none;
      font-size: 80%;
    }

    &:hover {
      background-color: $gray-100;
      transform: scale(1.02);
      .m08__action__delete {
        display: inline-block;
      }
    }


    //.m08__text {
    //  h2,
    //  h3 {
    //    .badge {
    //      float: right;
    //      text-transform: uppercase;
    //    }
    //  }
    //}

    .m08__image {
      img {
        width: 60px;
      }
    }

    @include media-breakpoint-down(md) {
      padding: $spacer;
      margin-bottom: $spacer / 2;
    }
  }
}
.import-clubs-modal {
  max-width: 640px;
  min-width: 640px;

  .doc-filename {
    color: white;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #23a566;

    i {
      margin-right: 7px;
    }
  }

  .modal-header {
    background: white;
  }


  .modal-footer {
    display: block;
    background: white;

    .tabs-view {
      display: flex;

      .tab {
        all: unset;
        font-size: 10px;
        margin-right: 10px;
        cursor: pointer;
        color: #d8d8d8;
        font-weight: 700;
        border-bottom: 1px solid transparent;
        padding: 2px 4px;

        &.active {
          border-color: #23a566;
          color: #23a566;
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
      }
    }
  }
}
