////////////////////////////////////////////////////////////////////////
//////// Layout
////////////////////////////////////////////////////////////////////////

body {
  background-image: url('/assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  /* Chrome 66+, Edge 79+, Opera 53+, Android Brower 80+ */
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution:.001dpcm) {
    @supports (background-image: -webkit-image-set(url('/assets/images/background.webp') 1x)) {
      background-image: -webkit-image-set(url('/assets/images/background.webp') 1x);
    }
  }

  /* FF 66+ */
  @supports (flex-basis: max-content) and (-moz-appearance: meterbar) {
    background-image: url(/assets/images/background.webp);
  }
}

easports-fifa-app {
  height: 100vh;
  overflow-y: scroll;
  display: block;
}

.content {
  margin-top: $navbar-height;
}

.container {
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }
}
