////////////////////////////////////////////////////////////////////////
//////// Upload
////////////////////////////////////////////////////////////////////////

.m12 {
  .m12__copy {
    a {
      color: $gray-700;
    }
  }
}
