////////////////////////////////////////////////////////////////////////
//////// Delivery Feed Activity
////////////////////////////////////////////////////////////////////////

.m02 {
  padding: $spacer * 1.5;
  display: block;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    padding: $spacer;
  }

  .m02__time {
    font-size: $small-font-size;
    color: $gray-500;
  }

  .m02__header {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: $spacer;
    h3 {
      flex: 2;
      margin: 0;
    }
  }

  .m02__actions {
    margin-top: $spacer;
    .btn {
      display: block;
      font-family: $headings-font-family;
      font-size: $font-size-lg * 1.2;
      margin-bottom: $spacer / 2;
      &.btn-disabled {
        background-color: $gray-300;
        border-color: $gray-300;
        cursor: default;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .m02__actions__info {
      padding: $spacer;
      border-radius: $border-radius;
      font-size: $font-size-base;
      background-color: $primary;
      color: white;
      cursor: pointer;
    }
  }

  .m02__info {
    font-weight: $font-weight-normal;
    color: $dark;
    margin-bottom: $spacer;
    padding: $spacer;
    background-color: #07F46833;
    border-radius: $border-radius;
    &.m02__info--nogallery {
      margin-bottom: 0;
    }
  }

  .m02__circle {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    display: inline-block;
    margin-right: $spacer / 2;
    &--reopened {
      background-color: $warning;
    }
    &--rejected {
      background-color: $danger;
    }
    &--approved {
      background-color: $success;
    }
  }

  .m02__gallery {
    @include make-row(2px);
    position: relative;
    &.m02__gallery--clickable {
      cursor: pointer;
    }
    .m02__gallery__image {
      @include make-col-ready(2px);
      @include make-col(3, $grid-columns);
      margin-bottom: 2px;
      @include media-breakpoint-down(md) {
        @include make-col(6, $grid-columns);
      }
    }
    &--league_approval {

      .m02__gallery__image {
        @include make-col(12, $grid-columns);
        @include media-breakpoint-down(md) {
          @include make-col(12, $grid-columns);
        }

      }
    }
  }

  // Package
  .m02__delivery-package {
    h2,
    h3 {
      .badge {
        font-size: 0.6em;
        padding: 3px 9px;
        line-height: 1em;
        position: relative;
        text-transform: uppercase;
      }
    }

    .m02__header {
      .m02__download {
        align-self: flex-end;
      }
    }

    // NEW
    &.m02__delivery-package--rejected {
      .m02__gallery {
        .m02__gallery__image {
          background-color: $gray-200;
          .m05__image {
            filter: grayscale(100%);
            opacity: 0.2;
          }
        }
      }
    }

    // NEW
    &.m02__delivery-package--new {
      &.m02__delivery-package--role-requester,
      &.m02__delivery-package--role-approver,
      &.m02__delivery-package--role-observer {
        .m02__gallery {
          &:after {
            content: '';
            width: 100%;
            height: calc(100% - 2px);
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.3;
            background-image: linear-gradient(
              135deg,
              $white 25%,
              $gray-200 25%,
              $gray-200 50%,
              $white 50%,
              $white 75%,
              $gray-200 75%,
              $gray-200 100%
            );
            background-size: 28.28px 28.28px;
          }
        }
      }
    }
  }

  // Comment
  .m02__comment {
    .m02__time {
      margin-bottom: $spacer;
    }

    .m02__gallery {
      margin-top: $spacer;
      .m02__gallery__image {
        @include make-col(2, $grid-columns);
        @include media-breakpoint-down(md) {
          @include make-col(6, $grid-columns);
        }

        .m05 {
          height: 120px;
          .m05__file,
          .m05__image {
            height: 120px;
          }
        }
      }
    }
    blockquote {
      margin-bottom: 0;
    }
    .m02__info {
      margin-bottom: 0;
      background-color: transparent;
    }
  }

  // Activity
  .m02__activity {
    .m02__time {
      margin-bottom: $spacer;
    }
    .m02__info {
      margin-bottom: 0;
      background-color: transparent;
    }
  }
}
