.modal-title {
  font-size: 22px;
}

.modal-header {
  .close {
    padding: 10px;
    margin: 0 -10px 0 0;
  }
}
