.btn {
  text-align: center;
  &.btn-primary {
  }
  &.btn--big {
    font-size: 20px;
    line-height: 22px;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    padding: 16px 20px;
    min-width: 100px;
    border-radius: 28px;
  }
  &.btn-outline-primary {
    border-color: $secondary;
  }
  &.btn-link {
    color: $dark
  }
}
